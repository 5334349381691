@tailwind base;

@layer base {
  @font-face {
    font-display: swap;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: bold;
    src: url('/assets/fonts/gotham-bold.otf') format('opentype'), local('Gotham Bold'), local('Gotham-Bold') ;
  }

  @font-face {
    font-family: 'Gotham';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: url('/assets/fonts/Gotham Book.otf') format('opentype'), local('Gotham Book'), local('Gotham-Book') ;
  }

  @font-face {
    font-display: swap;
    font-family: 'Bariol';
    font-style: normal;
    font-weight: bold;
    src: local('Bariol Bold'), local('Bariol-Bold'),
      url('/assets/fonts/Bariol-Bold.woff2') format('woff2'),
      url('/assets/fonts/Bariol-Bold.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Bariol';
    font-style: normal;
    font-weight: 300;
    src: local('Bariol Light'), local('Bariol-Light'),
      url('/assets/fonts/Bariol-Light.woff2') format('woff2'),
      url('/assets/fonts/Bariol-Light.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Bariol';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol Regular'), local('Bariol-Regular'),
      url('/assets/fonts/Bariol-Regular.woff2') format('woff2'),
      url('/assets/fonts/Bariol-Regular.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Futura';
    font-style: normal;
    font-weight: bold;
    src: local('Futura Bold 03'), local('Futura-Bold-03'),
      url('/assets/fonts/Futura-Bold-03.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Futura';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Medium 01'), local('Futura-Medium-01'),
      url('/assets/fonts/Futura-Medium-01.woff') format('woff');
  }

  body {
    font-family: Gotham;
  }
  html {
    scroll-behavior: smooth;
  }

  /* Torna possível setar a cor dos svgs usando as colors. Para casos onde a cor deve ser mantida, use a classe keep-color */
  svg:not(.keep-color) {
    circle,
    rect,
    path {
      fill: currentColor;
    }
  }
}

@tailwind components;
@tailwind utilities;
